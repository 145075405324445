import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import PageTransition from "gatsby-plugin-page-transitions"
import "./contact.styles.scss"

const Contact = () => {
  return (
    <PageTransition
      defaultStyle={{
        transition: "top 700ms cubic-bezier(0.47, 0, 0.75, 0.72)",
        top: "100%",
        position: "absolute",
        width: "100%",
      }}
      transitionStyles={{
        entering: { top: "0%" },
        entered: { top: "0%" },
        exiting: { top: "100%" },
      }}
      transitionTime={500}
    >
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>William Cheshier</title>
          <link rel="canonical" href="https://williamcheshier.com/about" />
        </Helmet>
        <h1 className="contact-header">Contact Me</h1>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
          integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
          crossorigin="anonymous"
        />
        <div className="div">
          <div className="form-wrapper">
            <form
              className="form"
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="thank-you"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <div className="header-row">
                <h2>Let's work together</h2>
              </div>
              <div className="contact-grid">
                <label className="label">
                  <p className="label-p">Name</p>
                  <input
                    className="input"
                    placeholder="Name"
                    name="name"
                    required
                  />
                </label>
                <label className="label">
                  <p className="label-p">Email</p>
                  <input
                    className="input"
                    placeholder="Email"
                    name="email"
                    type="email"
                    required
                  />
                </label>
              </div>
              <label className="label">
                <p className="label-p">Message</p>
                <textarea
                  className="textarea"
                  placeholder="Message"
                  name="message"
                  required
                />
              </label>
              <div className="contact-row">
                <button type="submit" className="contact-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <p className="contact-p" style={{ marginTop: "0px" }}>
            Email: wcheshier44@gmail.com
          </p>
          <p className="contact-p">Connect with me on social media</p>
          <div className="contact-social">
            <a
              className="contact-social"
              href="https://github.com/wchesh24"
              target="_blank"
            >
              <i className="fab fa-github"></i>
            </a>
            <a
              className="contact-social"
              href="https://www.instagram.com/thechesh24/"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="contact-social"
              href="https://www.facebook.com/will.cheshier.7?ref=bookmarks"
              target="_blank"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a className="contact-social" href="https://twitter.com/TheChesh24">
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </Layout>
    </PageTransition>
  )
}

export default Contact
